import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import PageHead from '../components/layout/page-head';
import TitleImage from '../components/layout/title-image';
import VisionMission from '../components/pages/about-us/vision-mission.about-us';
import OurTeam from '../components/pages/about-us/our-team';
import CompanyTimeline from '../components/pages/about-us/company-timeline';

const About = () => {
    return (
        <Layout>
            <TitleImage title='About Us'>
                <StaticImage
                    className='absolute w-full h-full z-0'
                    src='../images/about-us/text.png'
                    alt='large factory'
                />
            </TitleImage>
            <VisionMission />
            <OurTeam />
            <CompanyTimeline />
        </Layout>
    );
};

export const Head = () => (
    <PageHead
        title='About Us'
        description='Pacific Centric Sdn Bhd is a leading provider of system integration, custom software development, AI-powered solutions, industrial automation, and product design services. Partner with us for innovative technology solutions that drive your business forward'
    />
);

export default About;
