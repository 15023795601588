import React from 'react';
import classNames from 'classnames';
import * as css from './timeline-item.module.css';

export interface TimelineItemProps {
    alignment?: 'left' | 'right';
    text: string;
    date?: string;
}

const TimelineItem = ({ alignment = 'right', text, date }: TimelineItemProps) => {
    return (
        <div
            className={classNames(css.timeline_item, {
                [css.right]: alignment === 'right',
                [css.left]: alignment === 'left',
            })}
        >
            <div
                className={classNames(
                    'text-gray-600 border-2 border-gray-200 cursor-pointer rounded hover:shadow-lg transition-all',

                    css.description
                )}
            >
                {text}
            </div>
            {date && <div className={classNames('font-bold text-gray-700', css.date)}>{date}</div>}
            <div
                className={classNames(
                    'absolute border-4 bg-gray-50 rounded-full',
                    { 'border-red-500': !!date, 'border-gray-400': !!!date },
                    css.marker
                )}
            />
        </div>
    );
};

export default TimelineItem;
