import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import { BsLinkedin, BsSkype, BsTelegram, BsWhatsapp } from 'react-icons/bs';

export type SocialMedia = 'linkedin' | 'skype' | 'telegram' | 'whatsapp';

export interface MemberProfileProps {
    name: string;
    image: ReactNode;
    role: string;
    description: string;
    link?: [{ type: SocialMedia; link: string }];
}

const MemberProfile = ({ image, name, role, description, link }: MemberProfileProps) => {
    const getLink = (link_type: SocialMedia) => {
        return link?.find(l => l.type === link_type)?.link || '';
    };

    return (
        <div className='flex justify-center mb-10 lg:mb-0'>
            <div className='flex flex-col w-10/12'>
                <div className='flex justify-center h-full mb-4 rounded-lg'>{image}</div>
                <div className='text-lg text-center font-bold text-gray-700'>{name}</div>
                <div className='text-center text-gray-500 italic mb-3'>{role}</div>
                <div className='h-60 text-gray-600 text-center text-sm border-t border-b border-gray-200 py-3'>
                    {description}
                </div>
                <div className='inline-flex justify-center py-3 gap-x-3'>
                    <Link to={getLink('linkedin')}>
                        <BsLinkedin
                            className='hover:fill-red-600 fill-gray-600 transition-all cursor-pointer'
                            size='1.3rem'
                        />
                    </Link>
                    <Link to={getLink('skype')}>
                        <BsSkype
                            className='hover:fill-red-600 fill-gray-600 transition-all cursor-pointer'
                            size='1.3rem'
                        />
                    </Link>
                    <Link to={getLink('telegram')}>
                        <BsTelegram
                            className='hover:fill-red-600 fill-gray-600 transition-all cursor-pointer'
                            size='1.3rem'
                        />
                    </Link>
                    <Link to={getLink('whatsapp')}>
                        <BsWhatsapp
                            className='hover:fill-red-600 fill-gray-600 transition-all cursor-pointer'
                            size='1.3rem'
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default MemberProfile;
