import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import MemberProfile, { MemberProfileProps } from './member-profile';

const member_profiles: MemberProfileProps[] = [
    {
        name: 'Firdaus Omar',
        role: 'Project Director',
        description:
            'Firdaus Omar is a founder and project director at our company, with years of experience in integrating instrumentation, automation systems, and Human Machine Interfaces (HMIs).',
        image: (
            <StaticImage
                className='shadow-lg rounded-lg'
                src='../../../images/about-us/firdausomar.jpeg'
                alt='project director profile'
                formats={['auto', 'avif', 'webp']}
            />
        ),
    },
    {
        name: 'Fikri Puteh',
        role: 'Technical Director',
        description:
            'Fikri Puteh is a technical director with over 10 years of experience in system integration, including PLC programming, SCADA/HMI and DCS systems, process control systems, and remote communication link interface systems.',
        image: (
            <StaticImage
                className='shadow-lg rounded-lg'
                src='../../../images/about-us/fikriputeh.jpeg'
                alt='technical director profile'
                formats={['auto', 'avif', 'webp']}
            />
        ),
    },
    {
        name: 'Shahril Rafar',
        role: 'Business Development Manager',
        description:
            'Shahril Rafar is a business development manager with a strong track record of driving sales and revenue growth. He excels at identifying new business opportunities and developing partnerships that drive long-term success. Shahril is dedicated to helping our company achieve its goals and succeed in the industry.',
        image: (
            <StaticImage
                className='shadow-lg rounded-lg'
                src='../../../images/about-us/shahril.jpeg'
                alt='business development manager profile'
                formats={['auto', 'avif', 'webp']}
            />
        ),
    },
];

const OurTeam = () => {
    return (
        <section className='flex w-full justify-center border-b border-gray-300 pb-12 mb-12'>
            <div className='w-11/12 max-w-7xl'>
                <div className='text-3xl uppercase font-semibold text-center text-gray-700 mb-6'>Our Team</div>
                <div className='grid grid-cols-1 lg:grid-cols-3'>
                    {member_profiles.map(profile => {
                        return <MemberProfile key={profile.name} {...profile} />;
                    })}
                </div>
            </div>
        </section>
    );
};

export default OurTeam;
