import React from 'react';
import TimelineItem, { TimelineItemProps } from './timeline-item';

const timeline_items: TimelineItemProps[] = [
    { date: '2019', text: 'Company is founded', alignment: 'right' },
    { date: '', text: 'Hospital Sibu Incinerator Control Panel', alignment: 'right' },
    { date: '2020', text: 'EPCC of Hydraulic Control Valve at MLL2A', alignment: 'left' },
    { date: '', text: 'EPCC of TTLR Sulphur Facility Upgrade', alignment: 'left' },
    { date: '2021', text: 'Remote Monitoring System for IWK Cyberjaya Pump House', alignment: 'right' },
    {
        date: '2022',
        text: 'Instrument & Electrical Installation for Jaewon Frontier Factory Expansion',
        alignment: 'left',
    },
    {
        date: '',
        text: 'Kelulut Nest Remote Monitoring System',
        alignment: 'left',
    },
];

const Timeline = () => {
    return (
        <div className='relative flex flex-col'>
            <div className='absolute right-1/2 w-0.5 h-full bg-gray-200' />
            {timeline_items.map(item => {
                return <TimelineItem key={item.text} {...item} />;
            })}
        </div>
    );
};

export default Timeline;
