import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { BiTargetLock } from 'react-icons/bi';

interface MissionItemProps extends HTMLAttributes<HTMLLIElement> {}

const MissionItem = ({ children, className, ...props }: MissionItemProps) => {
    return (
        <li className={classNames(className, 'inline-flex items-center mb-2')} {...props}>
            <div className='self-start mr-1.5'>
                <BiTargetLock className='fill-red-400 mt-1' size='1.5rem' />
            </div>
            <div className='w-11/12'>{children}</div>
        </li>
    );
};

const VisionMission = () => {
    return (
        <section className='flex justify-center w-full py-10 bg-gray-50 border-t border-b border-gray-200 mb-12'>
            <div className=' max-w-7xl w-11/12 grid lg:grid-cols-2 gap-y-8 lg:gap-y-0 lg:gap-x-6'>
                <div className='flex flex-col w-full border-b border-gray-200 pb-8 lg:pb-0 lg:border-r lg:border-b-0'>
                    <div className='text-2xl text-gray-700 uppercase font-semibold mb-0.5'>Our Vision</div>
                    <div className='border-b-2 border-red-400 w-2/12 mb-3' />
                    <div className='text-gray-600'>
                        Our goal is to be a reputable provider of industrial and commercial products and technologies
                        across a range of industries. We aim to achieve this by providing high-quality products and
                        effective services that meet the needs and expectations of our customers. We are also committed
                        to introducing new technologies that will help our customers keep pace with the changing times.
                    </div>
                </div>
                <div className='flex flex-col w-full'>
                    <div className='text-2xl text-gray-700 uppercase font-semibold mb-0.5'>Our Mission</div>
                    <div className='border-b-2 border-red-400 w-2/12 mb-3' />
                    <ul className='flex flex-col text-gray-600'>
                        <MissionItem>
                            Continuously equip our organization with the latest knowledge and skills
                        </MissionItem>
                        <MissionItem>
                            Explore new methods and technologies to offer a wide range of products that can keep pace
                            with the fast-changing and demanding business environment
                        </MissionItem>
                        <MissionItem>
                            Establish partnerships with technology providers from around the world to stay up-to-date
                            with the latest technological developments and introduce them to our customers
                        </MissionItem>
                        <MissionItem>Remain relevant as a quality service provider</MissionItem>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default VisionMission;
