import React from 'react';
import Timeline from '../../timeline/timeline';

const CompanyTimeline = () => {
    return (
        <section className='w-full flex justify-center mb-20'>
            <div className='max-w-7xl w-11/12'>
                <div className='text-center text-3xl text-gray-700 font-bold leading-7 uppercase'>
                    Company Milestones
                </div>
                <div className='text-center text-gray-400 mb-8'> Brief History of Our Company</div>
                <Timeline />
            </div>
        </section>
    );
};

export default CompanyTimeline;
